import { createMakeStyles } from "tss-react";
import { createTheme } from "@mui/material";


export const theme = createTheme({
  palette: {
    "primary": {
      "dark": "#257eb7",
      "main": "#3291b7",
      "light": "#4fbab8",
    },  
    "secondary": {
      "main": "#fff",
      "dark": "#212932",
    }
  }
})

export const { 
  makeStyles,
  useStyles
} = createMakeStyles({ useTheme: () => theme });
