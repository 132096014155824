import KcAppBase, { useI18n  } from "keycloakify";
import { KcContext } from "kcContext";
import { useProps } from "style";
import Template from "Template";

export type Props = {
    kcContext: KcContext;
};

export default function KcApp({ kcContext }: Props) {

    const i18n = useI18n({
        kcContext,
        // NOTE: Here you can override the default i18n messages
        // or define new ones that, for example, you would have
        // defined in the Keycloak admin UI for UserProfile
        // https://user-images.githubusercontent.com/6702424/182050652-522b6fe6-8ee5-49df-aca3-dba2d33f24a5.png
        "extraMessages": {
            "en": {
                "doLogIn": "Log in"
                // Here we overwrite the default english value for the message "doForgotPassword" 
                // that is "Forgot Password?" see: https://github.com/InseeFrLab/keycloakify/blob/f0ae5ea908e0aa42391af323b6d5e2fd371af851/src/lib/i18n/generated_messages/18.0.1/login/en.ts#L17
                // "doForgotPassword": "I forgot my password"
            },
            // "fr": {
            //     /* spell-checker: disable */
            //     // "foo": "foo en Francais",
            //     // "doForgotPassword": "J'ai oublié mon mot de passe"
            //     /* spell-checker: enable */
            // },
        },
    });

    const styleProps = useProps();

    //NOTE: Locale not yet downloaded
    if (i18n === null) {
        return null;
    }

    const props = {
        i18n,
        Template,
        ...styleProps,
    }
    return <KcAppBase {...{ kcContext, ...props }} />;
}
