import { defaultKcProps } from "keycloakify";
import { makeStyles } from "theme";
import logo from "./img/metis.png"

const useStyles = makeStyles()(theme => ({
    "kcHtmlClass": {
        "background": theme.palette.secondary.main,
        "& body": {
            "background": theme.palette.secondary.main,
            "fontWeight": "400 !important",
            "lineHeight": "1.5 !important",
        },
        "& a": {
            "color": theme.palette.primary.main,
            "&:hover": {
                "color": theme.palette.primary.light,
            },
        }
    },
    "kcFormCardClass": {
        "borderRadius": "4px",
    },
    "kcHeaderClass": {
        "marginBottom": "0px !important"
    },
    "kcHeaderWrapperClass": {
        "color": theme.palette.primary.main,
        "paddingTop": "0px !important",
        "paddingRight": "0px !important",
        [theme.breakpoints.up('sm')]: {
            "paddingTop": "38px !important",
            "paddingBottom": "0px !important",
        },
    },
    "kcButtonClass": {
        "borderRadius": "4px",
        "padding": "8px 16px", 
    },
    "kcButtonPrimaryClass": {
        "display": "block",
        "background": theme.palette.primary.main,
        "color": theme.palette.secondary.main,
        "border": "unset",
        "width": "100%",
        "&:hover": {
            "backgroundColor": theme.palette.primary.light
        },
        [theme.breakpoints.up('md')]: {
            "margin": "auto",
            "width": "50% !important",
        },
    },
    "kcInputClass": {
        "borderRadius": "4px",
        "fontSize": "16px",
        "lineHeight": "1.5",
        "borderColor": theme.palette.secondary.dark,
        "&:focus": {
            "borderColor": theme.palette.primary.main,
            "boxShadow": "inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px " + theme.palette.primary.main,
        },
        "&:hover": {
            "borderColor": theme.palette.primary.light
        }
    },
    "kcLabelClass": {
        "fontWeight": "unset",
    },
    "kcAlertClass": {
        "borderRadius": "4px",
    },
    "kcLogoClass": {
        "display": "block",
        "margin": "auto",
        "height": "100px",
        [theme.breakpoints.up('md')]: {
            "height": "150px"
        },
    },
    "kcLocaleWrapperClass": {
        "& ul": {
            "& a:hover": {
                "backgroundColor": theme.palette.primary.light + " !important",
            },
        },
    },
}));

export function useProps() {

    const { classes } = useStyles();
    
    return {
        ...defaultKcProps,
        "kcHtmlClass": [...defaultKcProps.kcHtmlClass, classes.kcHtmlClass],
        "kcHeaderClass": [...defaultKcProps.kcHeaderClass, classes.kcHeaderClass],
        "kcHeaderWrapperClass": [...defaultKcProps.kcHeaderWrapperClass, classes.kcHeaderWrapperClass],
        "kcFormCardClass": [...defaultKcProps.kcFormCardClass, classes.kcFormCardClass],
        "kcButtonClass": [...defaultKcProps.kcButtonClass, classes.kcButtonClass],
        "kcButtonPrimaryClass": [...defaultKcProps.kcButtonPrimaryClass, classes.kcButtonPrimaryClass],
        "kcInputClass": [...defaultKcProps.kcInputClass, classes.kcInputClass],
        "kcLabelClass": [...defaultKcProps.kcLabelClass, classes.kcLabelClass],
        "kcAlertClass": [classes.kcAlertClass],
        "kcAlertTitleClass": [classes.kcAlertClass],
        "kcLogoClass": [classes.kcLogoClass],
        "kcLocaleWrapperClass": [...defaultKcProps.kcLocaleWrapperClass, classes.kcLocaleWrapperClass],
        logo,
        displayRealmName: false
    }
}
